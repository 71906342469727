<template>
  <div
    class="w-full border overflow-hidden"
    :class="{
      'border-bb-brand-purple': isActive,
      'rounded-t-md border-1': rounded === 'top' && isActive,
      'rounded-b-md': rounded === 'bottom' && isActive,

      'border-bb-neutral-100': !isActive,
      'rounded-t-md border-b-0': rounded === 'top' && !isActive,
      'rounded-b-md border-t-0': border === 'bottom' && rounded === 'bottom' && !isActive,
      'rounded-b-md border-1': border === 'top' && !isActive && rounded === 'bottom',

      'rounded-lg border-1': !isOnboarding,
    }"
  >
    <div
      :class="[isActive ? 'bg-neutral-0' : 'bg-white']"
      class="h-24"
      @click="$emit('select-channel')"
    >
      <!--HEADER-->
      <div class="flex justify-between items-center px-4 py-6 cursor-pointer">
        <div class="flex gap-4 items-center">
          <div class="w-12 flex justify-center">
            <slot name="icon" />
          </div>
          <div>
            <h5 class="h5">
              <slot name="card-title" />
            </h5>
            <p class="text-sm text-bb-text-secondary">
              <slot name="card-description" />
            </p>
          </div>
        </div>
        <div
          v-if="hasConnectedAccount"
          class="text-sm bg-bb-success-bg text-bb-success-text rounded-full px-3"
        >
          Connected
        </div>
        <ic-chevron :direction="isActive ? 'down' : 'right'" />
      </div>
    </div>

    <keep-alive>
      <google-ads-integration
        v-if="isActive && googleAds"
        :is-onboarding="isOnboarding"
        @update="onConnectedAccount"
      />
    </keep-alive>
  </div>
</template>

<script>
import IcChevron from '@/components/icon/ic-chevron'
import GoogleAdsIntegration from '@/components/onboard-components-v2/GoogleAdsIntegration'

export default {
  name: 'ChannelCard',
  components: {
    GoogleAdsIntegration,
    IcChevron,
  },
  inject: ['context'],
  props: {
    googleAds: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    border: {
      type: String,
      default: '',
      validator: val => ['top', 'bottom', ''].includes(val),
    },
    rounded: {
      type: String,
      default: '',
      validator: val => ['top', 'bottom', ''].includes(val),
    },
    isOnboarding: {
      type: Boolean,
      default: false,
    },
    hasConnectedAccount: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onConnectedAccount(googleAdsStore, accountList) {
      if (googleAdsStore && accountList) {
        this.$emit('connected', googleAdsStore, accountList)
      }
    },
  },
}
</script>
