<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="welcome-container text-center w-full space-y-4 pb-40">
      <img
        src="/content/images/brightbid/colored-brightbid-logo-only.svg"
        class="onboarding-rectangles mx-auto"
        alt="Brightbid"
      />
      <h1 class="h1a">Gathering data...</h1>
      <div class="text-bb-text-secondary">
        <p>This might take a few seconds, please don’t close this page.</p>
      </div>
      <div class="animate-pulse rounded-full bg-neutral-50 h-4 w-full"></div>
    </div>
  </div>
</template>

<script></script>

<script>
export default {
  name: 'LoadingPage',
  mounted() {
    // Simulate loading time 5 seconds
    const loadingTime = 5000
    setTimeout(() => {
      this.$emit('finish')
    }, loadingTime)
  },
}
</script>

<style scoped>
.welcome-container {
  width: 635px;
}
</style>
