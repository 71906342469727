<template>
  <div
    class="space-y-2"
    :class="{ 'space-y-6': inputErrorMessage }"
  >
    <text-input
      v-model="input"
      :tooltip="tooltip"
      :label="label"
      :sub-label="subLabel"
      class="bb-text-secondary-gray"
      input-type="text"
      input-name="input_terms"
      input-id="input_terms"
      :placeholder="placeholder"
      :error-message="inputErrorMessage"
      @keydown.enter.prevent="addInput"
    >
      <template #tooltip-content>
        <slot name="tooltip-content" />
      </template>
      <template #suffix>
        <div class="h-10 w-24 absolute right-0 top-0 p-2">
          <div
            v-if="input"
            class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
            @click="addInput"
          >
            <ic-plus :size="16" />
            <p>Add</p>
          </div>
        </div>
      </template>
    </text-input>
    <!-- Display Chips -->
    <div class="flex flex-wrap gap-2">
      <div
        v-for="(term, index) in inputList"
        :key="index"
        class="gap-2 bg-bb-blue-pale-secondary hover:shadow-drop-8 font-medium rounded border-2 border-bb-blue-tertiary text-bb-blue cursor-pointer flex flex-row px-2 items-center"
        @mouseover="hoveredIndex = index"
        @mouseleave="hoveredIndex = null"
        @click="removeText(index)"
      >
        <ShieldCheckOutline
          :size="16"
          class="p-px rounded-custom bg-bb-blue-pale"
        />
        <p>{{ term }}</p>
        <ic-cross :size="16" />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/input/brightbid/TextInput'
import { standardCharacters } from '@/utils/validators'
import ShieldCheckOutline from 'vue-material-design-icons/ShieldCheckOutline.vue'
import IcCross from 'vue-material-design-icons/Close.vue'

export default {
  name: 'ChipInput',
  components: { TextInput, ShieldCheckOutline, IcCross },
  props: {
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    paddingLeft: {
      type: String,
      default: '0',
    },
    paddingRight: {
      type: String,
      default: '0',
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    tooltipTheme: {
      type: String,
      default: 'sm',
    },
    inputList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: '',
      hoveredIndex: 999,
    }
  },
  computed: {
    inputErrorMessage() {
      if (!this.$v.input.$dirty) return null
      if (!this.$v.input.standardCharacters) return this.errorMessage || 'Invalid characters'
      return null
    },
  },
  validations() {
    return {
      input: {
        standardCharacters,
      },
    }
  },
  methods: {
    addInput() {
      const input = this.capitalizeWords(this.input.trim())
      if (input.length === 0) {
        // Empty term, do not add
        return
      }

      this.$v.input.$touch()

      if (this.$v.input.$invalid) {
        return
      }

      if (!this.inputList.includes(input)) {
        this.$emit('update', [...this.inputList, input])
        this.hoveredIndex = null
        this.input = ''
      }

      this.$v.input.$reset()
    },
    removeText(index) {
      this.$emit(
        'update',
        this.inputList.filter((_, i) => i !== index),
      )
      this.hoveredIndex = null
    },
    capitalizeWords(string) {
      if (!string) return ''
      return string.replace(/\b\w/g, char => char.toUpperCase())
    },
  },
}
</script>
