<template>
  <div>
    <!--1. No displayed accounts-->
    <div
      v-if="!hasAvailableAccounts && !isLoading && !hasConsent"
      class="h-32 p-8 space-y-3"
    >
      <p class="text-sm text-bb-text-default">Sign in to Google to sync with Google Ads</p>
      <GoogleAllowAccessButton
        white
        name="Sign in with google"
        :access-scope="googleConfig.scopes.connectStore"
        @verify-token="verifyToken"
      >
        <span class="text-sm">Sign in with Google</span>
      </GoogleAllowAccessButton>
    </div>
    <!--2. Loading-->
    <div
      v-if="isLoading"
      class="h-32 w-full flex items-center justify-center"
    >
      <p>LOADING...</p>
    </div>

    <!--3. Display a list of Google Accounts-->
    <div
      v-if="hasAvailableAccounts && !isLoading && hasConsent"
      class="py-4 px-6 space-y-6"
    >
      <div class="text-sm">
        <div v-if="accountList.length > 0">
          <p>Accounts connected to your Google account:</p>
          <text-input
            v-model="searchQuery"
            :disabled="isLoading"
            input-name="search-query"
            input-id="search-query"
            input-type="text"
            placeholder="Search for an account"
          />
        </div>
        <p v-else>No accounts found</p>
      </div>
      <div class="text-sm border border-neutral-100 divide-y divide-neutral-100">
        <div
          v-for="(googleAdsStore, index) in paginationRows"
          :key="index"
          class="flex justify-between items-center px-6 h-16"
        >
          <p>
            {{ googleAdsStore.name }}&ensp;<span class="font-bold">{{ googleAdsStore.readable_id }}</span>
          </p>
          <MergeButtonSquare
            v-if="
              connectedAccount.readable_id !== googleAdsStore.readable_id &&
              selectedAccount.readable_id !== googleAdsStore.readable_id
            "
            button-type="google"
            @click="selectGoogleAdsStore(googleAdsStore)"
          >
            Connect
          </MergeButtonSquare>
          <div
            v-else
            class="bg-bb-success-bg text-bb-success-text rounded-full py-1 px-3 m-3"
          >
            {{ selectedAccount.readable_id === googleAdsStore.readable_id ? 'Selected' : 'Connected' }}
          </div>
        </div>

        <div
          v-if="searchQuery && totalItems <= 0"
          class="flex gap-2 items-center px-12 h-16 bg-neutral-0 cursor-pointer"
        >
          <p class="font-medium">No accounts found</p>
        </div>
        <div class="flex gap-2 items-center px-12 h-16 bg-neutral-0 cursor-pointer">
          <ic-plus
            class="text-bb-brand-purple"
            size="16"
          />
          <p class="font-medium text-bb-brand-purple">Create a new Google Ads account</p>
        </div>
      </div>
      <div v-if="rowsPerPage < accountList.length">
        <pagination
          :limit="rowsPerPage"
          :page="currentPage"
          :max="totalItems"
          @onChange="changePage"
        />
      </div>

      <p
        v-if="!connectedAccount.id"
        class="text-sm"
      >
        Cant’s find your Google Ads account? You might need to log in with a different Google account
      </p>
      <p
        v-else
        class="text-sm"
      >
        Would you prefer selecting a different account?
      </p>
      <GoogleAllowAccessButton
        white
        name="Connect to another Google account"
        :access-scope="googleConfig.scopes.connectStore"
        @verify-token="verifyToken"
      >
        <span class="text-sm">Connect to another Google account</span>
      </GoogleAllowAccessButton>
    </div>
  </div>
</template>

<script>
import IcPlus from '@/components/icon/ic-plus'
import Pagination from '@/components/table/Pagination'
import googleConfig from '@/configs/google-client'
import { mapGetters } from 'vuex'
import GoogleAllowAccessButton from '@/components/btn/Google/GoogleAllowAccessButton.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'

export default {
  name: 'GoogleAdsIntegration',
  components: {
    GoogleAllowAccessButton,
    IcPlus,
    Pagination,
    TextInput,
  },
  inject: ['context'],
  props: {
    hasConnectedAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // pagination
      currentPage: 1,
      rowsPerPage: 5,
      searchQuery: '',
      totalItems: 0,
      isLoading: false,
      accountList: [],
      connectedAccount: {},
      hasAvailableAccounts: false,
      hasConsent: false,
      googleConfig: googleConfig,
      selectedAccount: {},
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/getAccount',
      user: 'auth/user',
    }),
    paginationRows() {
      if (!this.accountList) return []

      let filteredAccounts = this.accountList

      if (this.searchQuery) {
        const searchQueryLower = this.searchQuery.toLowerCase()
        filteredAccounts = this.accountList.filter(
          account =>
            (account.name && account.name.toLowerCase().includes(searchQueryLower)) ||
            (account.readable_id && account.readable_id.toLowerCase().includes(searchQueryLower)),
        )
      }
      this.updatePagination(filteredAccounts.length)
      return filteredAccounts.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage)
    },
  },
  watch: {
    searchQuery() {
      this.currentPage = 1 // Reset to page 1 on new search
    },
    hasConnectedAccount(newValue) {
      this.hasAvailableAccounts = newValue
    },
  },
  mounted() {
    this.connectedAccount = this.context.integration || {}
    if (this.context.accountList) {
      this.accountList = this.context.accountList
      this.hasConsent = true
      this.hasAvailableAccounts = true
      this.isLoading = false
      this.initializeAccountList()
    }
  },
  methods: {
    initializeAccountList() {
      if (this.accountList.length > 0) {
        this.hasAvailableAccounts = true
        this.currentPage = 1
        this.filterAccounts()
      }
    },
    changePage(paginationData) {
      this.currentPage = paginationData.current_page
      this.filterAccounts()
    },
    filterAccounts() {
      this.updatePagination(this.accountList.length)
    },
    updatePagination(totalItems) {
      this.totalItems = totalItems
    },
    async verifyToken(response) {
      try {
        this.isLoading = true
        let integrations = await this.$http.post(`common/integration/google/ads/grant`, {
          api_token: response.code,
        })

        this.hasConsent = true

        if (integrations?.data?.accounts) {
          this.accountList = integrations.data.accounts
          this.hasAvailableAccounts = true
          this.initializeAccountList()
        }

        this.isLoading = false
        this.disabled = false
        this.$emit('integration-finished')
        return true
      } catch (error) {
        this.hasAvailableAccounts = false
        this.isLoading = false
        console.log(error)
      }
    },
    selectGoogleAdsStore(googleAdsStore) {
      this.selectedAccount = googleAdsStore
      this.$emit('update', googleAdsStore, this.accountList)
    },
  },
}
</script>

<style scoped></style>
