<template>
  <div class="bg-white h-full flex justify-center">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Loading...</p>
        </brightbid-loader>
      </div>
    </div>
    <div class="w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="activeStepIndex"
      />

      <div class="text-center py-8">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />

        <span class="text-2xl font-semibold mb-6 text-bb-text-default">Set up an Account</span>

        <!-- <p class="text-bb-text-secondary mb-6">
          Begin with creating an Account. You will be able to create more Accounts later.
        </p> -->
      </div>
      <form
        class="w-390 mx-auto pb-20"
        @keydown.prevent.enter="submit"
      >
        <div class="pb-12 space-y-6">
          <search-input
            ref="organizationInput"
            v-model="siteForm.organization"
            input-id="organization"
            input-name="organization"
            label="Select Organization"
            tooltip
            tooltip-theme="lg"
            tooltip-text="An “Organization“ is where you'll find the overall information and activities related to your company, including the performance of each advertising platform you use, along with user and payment details."
            sub-label="Which Organization does your new account belong to?"
            :placeholder="siteForm.organization ? siteForm.organization.label : 'Select organization'"
            :options="organizationOptions"
            :error-message="$v.siteForm.organization.$error ? 'Please enter an organization' : null"
            @select-item="updateOrganization"
            @focusout="$v.siteForm.organization.$touch"
            @select-custom-option="addOrganization"
          >
            <template #custom-option="{ input, emitCustomOption }">
              <div
                class="text-bb-brand-purple text-sm flex items-center px-3 py-2 cursor-pointer"
                @click="emitCustomOption(input)"
              >
                <ic-plus size="16" />
                Add New Organization
              </div>
            </template>
          </search-input>

          <text-input
            v-model="siteForm.siteName"
            class="w-full"
            label="Account Name"
            tooltip
            tooltip-direction="bottom-start"
            input-type="text"
            input-name="siteName"
            input-id="siteName"
            placeholder="Account Name"
            :error-message="$v.siteForm.siteName.$error ? 'Please enter an account name' : null"
            @focusout="$v.siteForm.siteName.$touch"
          >
            <template #tooltip-content>
              <span class="font-medium mb-2">
                "An “Account“ is your connected Google Ads account and usually represents a single website within your
                Organization"
              </span>
            </template>
          </text-input>
          <url-input
            v-model="siteForm.siteURL"
            class="w-full"
            label="What’s your URL?"
            input-type="text"
            input-name="siteURL"
            input-id="siteURL"
            placeholder="Website"
            :error-message="$v.siteForm.siteURL.$error ? 'Please enter a URL' : null"
            @focusout="$v.siteForm.siteURL.$touch"
          />

          <search-input
            ref="countryInput"
            v-model="siteForm.siteCountry"
            input-id="country"
            input-name="country"
            label="Country"
            sub-label="Where are you located?"
            tooltip
            tooltip-text="The main country is where most, if not all, of the site's activity occurs. This country is the target for both search and shopping campaigns"
            :placeholder="siteForm.siteCountry ? siteForm.siteCountry.label : 'Select Country'"
            :options="countryOptions"
            :error-message="$v.siteForm.siteCountry.$error ? 'Please enter a site country' : null"
            @select-item="onCountryChange"
            @focusout="$v.siteForm.siteCountry.$touch"
          />
          <search-input
            ref="languageInput"
            v-model="siteForm.siteLanguage"
            input-id="language"
            input-name="language"
            label="Default Language"
            sub-label="What is the most common language used in your ads?"
            tooltip
            tooltip-text="The main language is used in the product feed and is the language you must use to describe your business in the next step."
            :placeholder="siteForm.siteLanguage ? siteForm.siteLanguage.label : 'Select language'"
            :options="languageOptions"
            :error-message="$v.siteForm.siteLanguage.$error ? 'Please enter a language' : null"
            @select-item="updateLanguage"
            @focusout="$v.siteForm.siteLanguage.$touch"
          />
        </div>

        <div class="flex justify-between space-x-16">
          <MergeButtonRound
            button-type="secondary"
            class="flex"
            @click="$emit('prev')"
          >
            <div class="flex"><ic-chevron-left /> Previous</div>
          </MergeButtonRound>

          <MergeButtonRound
            brightbid
            type="submit"
            :disabled="isLoading"
            @click="submit"
          >
            <div class="flex">Next <ic-chevron-right /></div>
          </MergeButtonRound>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
// import icInfo from 'vue-material-design-icons/InformationOutline'
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import TextInput from '@/components/input/brightbid/TextInput'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import UrlInput from '@/components/input/brightbid/URLInput'
import { mapActions, mapState, mapGetters } from 'vuex'
import SearchInput from '@/components/input/brightbid/SearchInput'
import IcPlus from '@/components/icon/ic-plus'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import Toast from '@/components/shared/Toast'

export default {
  name: 'AccountSetup',
  components: {
    IcPlus,
    IcChevronLeft,
    IcChevronRight,
    TextInput,
    HorizontalStepper,
    UrlInput,
    SearchInput,
    // eslint-disable-next-line
    Toast,
    BrightbidLoader,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      steps: ['Connect', 'Account Set Up', 'Business Details', 'Targets'],
      activeStepIndex: 1,
      siteForm: {
        websiteOrAmazon: true,
        siteURL: null,
        siteName: null,
        siteCountry: null,
        siteLanguage: null,
        organization: null,
      },
      options: [
        {
          label: 'My Website',
          value: 'website',
          icon: 'ic-my-website',
        },
        {
          label: 'Amazon',
          value: 'amazon',
          icon: 'ic-amazon-letter-logo',
        },
      ],
      isLoading: false,
      organizationOptions: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('country', {
      allCountries: 'countries',
      countryLoadingStatus: 'loading',
      countryErrorStatus: 'error',
    }),
    ...mapState('language', {
      allLanguages: 'languages',
      languageLoadingStatus: 'loading',
      languageErrorStatus: 'error',
    }),
    ...mapGetters('country', {
      formattedAmazonMarkets: 'formattedAmazonMarkets',
    }),
    ...mapState('organization', ['userOrganization']),
    countryOptions() {
      return this.allCountries
    },
    languageOptions() {
      return this.allLanguages
    },
  },
  validations() {
    return {
      siteForm: {
        websiteOrAmazon: { required },
        siteURL: { required, url },
        siteName: { required },
        siteCountry: { required },
        siteLanguage: { required },
        organization: { required },
      },
    }
  },
  async mounted() {
    this.initializeForm()
    await this.fetchCountries()
    await this.fetchAmazonAvailableMarketsData()
    await this.fetchLanguages()

    if (this.user.id && !this.userOrganization) {
      await this.loadUserOrganizationList(this.user.id)
    }
    this.initializeOrganizationOptions()
  },
  methods: {
    async submit() {
      if (this.$v.siteForm.$invalid) {
        this.$v.siteForm.$touch()
        return this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'One or more fields are not valid',
            type: 'error',
          },
        })
      }
      this.$emit('next', this.siteForm)
    },
    initializeForm() {
      this.siteForm.siteURL = this.context.siteURL || this.context?.integration?.url || 'https://'
      this.siteForm.siteName = this.context?.integration?.name || ''
      this.siteForm.siteCountry = this.context?.siteCountry || null
      this.siteForm.siteLanguage = this.context?.siteLanguage || null
      if (this.context?.organizationName) {
        this.siteForm.organization = {
          label: this.context.organizationName,
          value: this.context.organizationID,
        }
      }
    },
    onCountryChange(newCountry) {
      if (this.siteForm.siteCountry !== newCountry) {
        this.siteForm.siteLanguage = null
        this.$refs.languageInput.selectedItem = null
        this.$refs.languageInput.searchInput = null
      }
      this.siteForm.siteCountry = newCountry
      if (this.siteForm.websiteOrAmazon !== 'amazon') return

      const selectedCountry = this.formattedAmazonMarkets.find(
        country => country.value.toLowerCase() === newCountry.value.toLowerCase(),
      )
      if (selectedCountry && selectedCountry.languages) {
        this.siteForm.siteLanguage = selectedCountry.languages[0]
        this.$refs.languageInput.selectedItem = selectedCountry.languages[0]
        this.$refs.languageInput.searchInput = selectedCountry.languages[0].label
      }
    },
    updateLanguage(newLanguage) {
      this.siteForm.siteLanguage = newLanguage
    },
    clearCountryAndLanguage() {
      this.siteForm.siteCountry = null
      this.siteForm.siteLanguage = null

      if (!this.$refs.countryInput || !this.$refs.languageInput) return
      this.$refs.countryInput.selectedItem = null
      this.$refs.countryInput.searchInput = null
      this.$refs.languageInput.selectedItem = null
      this.$refs.languageInput.searchInput = null
    },
    onSiteTypeChange() {
      this.clearCountryAndLanguage()
    },
    updateOrganization(organization) {
      this.siteForm.organization = organization
    },
    initializeOrganizationOptions() {
      this.organizationOptions = this.userOrganization.map(org => ({
        label: org.name,
        value: org.id,
      }))
    },
    addOrganization(option) {
      const newOrganization = {
        label: option.label,
        value: null,
      }
      this.organizationOptions.push(newOrganization)
    },
    ...mapActions('country', ['fetchCountries', 'fetchAmazonAvailableMarketsData']),
    ...mapActions('language', ['fetchLanguages']),
    ...mapActions('organization', ['loadUserOrganizationList']),
  },
}
</script>
