<template>
  <div class="bg-white h-full flex justify-center">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Saving changes...</p>
        </brightbid-loader>
      </div>
    </div>
    <div class="md:w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="activeStepIndex"
      />
      <div class="text-center py-8">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />
        <div class="text-2xl text-bb-text-headers font-medium">Business details for the Performance engine</div>
        <div class="text-bb-text-secondary pb-6 px-6">
          <p>
            Help our AI understand your business better to make smarter decisions:The more details you provide, the
            better our AI can tailor your ad strategies.
          </p>
        </div>
      </div>

      <form
        class="w-390 mx-auto pb-20"
        @keydown.enter.prevent
      >
        <div>
          <p class="text-sm font-medium pb-4">How would you categorize your business?</p>
          <div>
            <div class="flex gap-4 justify-between h-24">
              <div
                class="cursor-pointer pl-4 w-full h-full flex items-center rounded-md border border-bb-neutral-100 transition duration-200 ease-in-out hover:border-bb-brand-purple"
                :class="B2B ? 'bg-bb-brand-purple' : 'bg-white'"
                @click="toggleCheckBox('B2B')"
              >
                <div class="flex flex-row gap-4">
                  <check-box
                    input-name="b2b"
                    input-id="b2b"
                    :value="B2B"
                    @input="toggleCheckBox('B2B')"
                  />
                  <div>
                    <B2B_icon :state="B2B ? 'selected' : ''" />
                    <p
                      class="text-xs"
                      :class="B2B ? 'text-white' : ''"
                    >
                      Business to Business
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="cursor-pointer pl-4 w-full h-full flex items-center rounded-md border border-bb-neutral-100 transition duration-200 ease-in-out hover:border-bb-brand-purple"
                :class="B2C ? 'bg-bb-brand-purple' : 'bg-white'"
                @click="toggleCheckBox('B2C')"
              >
                <div class="flex flex-row gap-4">
                  <check-box
                    input-name="b2c"
                    input-id="b2c"
                    :value="B2C"
                    @input="toggleCheckBox('B2C')"
                  />
                  <div>
                    <B2C_icon :state="B2C ? 'selected' : ''" />
                    <p
                      class="text-xs"
                      :class="B2C ? 'text-white' : ''"
                    >
                      Business to Customers
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-bb-error feedback-message pt-2">{{ businessCategoryErrorMessage }}</div>
          </div>

          <text-area
            v-model="siteInfoForm.businessDescription"
            class="w-full pt-2"
            label="Briefly describe your business"
            sub-label="Use a short and focused description (eg: “Store that sells affordable furniture and home goods“)"
            input-type="text"
            input-name="name"
            input-id="name"
            placeholder="Business description"
            :rows="3"
            :max-length="inputLengths.businessDescription.max"
            :min-length="inputLengths.businessDescription.min"
            :error-message="businessDescriptionErrorMessage"
            tooltip
            tooltip-theme="lg"
            @focusout="$v.siteInfoForm.businessDescription.$touch"
          >
            <template #tooltip-content>
              <span class="font-medium mb-2">Example of business descriptions:</span>
              <div class="flex flex-col gap-2 mt-2">
                <p>
                  <span class="font-bold text-bb-green-accepted">Good: </span>“Store that sells affordable furniture and
                  home goods.”
                </p>
                <p>
                  <span class="font-bold text-bb-warning-200">Bad: </span>“IKEA is a global home furnishings retailer
                  known for its affordable and stylish furniture, as well as a wide range of home accessories and decor.
                  The company follows a self-assembly model, enabling cost-effective production and efficient customer
                  transportation. IKEA is renowned for its Scandinavian design influence and commitment to
                  sustainability.”
                </p>
                <p>
                  <span class="font-bold text-bb-error">Very Bad: </span>“Transform your living spaces with IKEA’s
                  innovative and affordable furniture solutions. Elevate your style, simplify your life – because at
                  IKEA, we believe in creating homes that reflect who you are.”"
                </p>
              </div>
            </template>
          </text-area>

          <search-input
            ref="industryInput"
            v-model="siteInfoForm.industry"
            input-id="industry"
            input-name="industry"
            class="pb-6"
            label="Industry"
            sub-label="What industry are you in?"
            :placeholder="siteInfoForm.industry ? siteInfoForm.industry.label : 'Eg: Medical, IT, Sports'"
            :options="industryOptions"
            :error-message="$v.siteInfoForm.industry.$error ? 'Please enter an industry' : null"
            @select-item="updateIndustry"
            @focusout="$v.siteInfoForm.industry.$touch"
            @select-custom-option="addCustomIndustry"
          >
            <template #custom-option="{ input, emitCustomOption }">
              <div
                class="text-bb-brand-purple text-sm flex items-center px-3 py-2 cursor-pointer"
                @click="emitCustomOption(input)"
              >
                <ic-plus size="16" />
                Add industry
              </div>
            </template>
          </search-input>

          <chip-input
            :input-list="siteInfoForm.brandTerms"
            class="pb-6"
            tooltip
            label="Add your brand terms"
            sub-label="What would customers search for to find your brand?"
            placeholder="Type here..."
            error-message="Invalid characters in brand term"
            @update="updateBrandTerms"
          >
            <template #tooltip-content>
              <span class="font-medium mb-2">
                Enter your most important brand terms one by one. This includes the brand name and common misspellings,
                and excludes any generic words.
                <div class="mt-1">E.g.: <span class="italic">brightbid, bright bid, britebid, brite bid.</span></div>
                <div class="mt-1">
                  If the brand name contains a generic word (e.g. hotels.com), do not include this generic word
                  ("hotels" in this example) in this list. Instead you could enter the domain(s) as brand term(s):
                  "hotels.com"
                </div>
              </span>
            </template>
          </chip-input>

          <chip-input
            hidden
            :input-list="siteInfoForm.competitorBrandTerms"
            label="Add your main competitors brand"
            sub-label="Who should we keep an eye on?"
            placeholder="Type here..."
            error-message="Invalid characters in competitor's brand term"
            @update="updateCompetitorBrandTerms"
          >
          </chip-input>
        </div>

        <div class="flex justify-between mt-10">
          <MergeButtonRound
            button-type="secondary"
            :disabled="isLoading"
            @click="$emit('prev')"
          >
            <template #left-icon>
              <IcChevronLeft />
            </template>
            Previous
          </MergeButtonRound>
          <!-- :disabled="isLoading || $v.siteInfoForm.$invalid" -->
          <MergeButtonRound
            type="submit"
            @click="submit"
          >
            <template #right-icon>
              <IcChevronRight />
            </template>
            Next
          </MergeButtonRound>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import TextArea from '@/components/input/brightbid/TextArea'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import CheckBox from '@/components/input/brightbid/CheckBox'
import B2B_icon from '@/components/icon/brightbid/b2b_icon'
import B2C_icon from '@/components/icon/brightbid/b2c_icon'
import { mapActions } from 'vuex'
import Toast from '@/components/shared/Toast'
import SearchInput from '@/components/input/brightbid/SearchInput'
import ChipInput from '@/components/input/ChipInput'
import IcPlus from '@/components/icon/ic-plus'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

export default {
  name: 'CreateSiteWebsite',
  components: {
    IcChevronLeft,
    IcChevronRight,
    TextArea,
    HorizontalStepper,
    CheckBox,
    B2B_icon,
    B2C_icon,
    SearchInput,
    ChipInput,
    IcPlus,
    BrightbidLoader,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      steps: ['Connect', 'Account Set Up', 'Business Details', 'Targets'],
      activeStepIndex: 2,
      B2B: false,
      B2C: false,
      siteInfoForm: {
        target: [],
        businessDescription: null,
        industry: null,
        brandTerms: [],
        competitorBrandTerms: [],
      },
      inputLengths: {
        businessDescription: {
          min: 25,
          max: 150,
        },
      },
      isLoading: false,
      industries: [],
      industryOptions: [],
    }
  },
  computed: {
    businessDescriptionErrorMessage() {
      const lengths = this.inputLengths.businessDescription
      if (!this.$v.siteInfoForm.businessDescription.$dirty) return null
      if (!this.$v.siteInfoForm.businessDescription.required) return 'Description is required'
      if (!this.$v.siteInfoForm.businessDescription.minLength) return `Please use at least ${lengths.min} characters.`
      if (!this.$v.siteInfoForm.businessDescription.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
    businessCategoryErrorMessage() {
      if (!this.$v.siteInfoForm.target.$dirty) return null
      if (!this.$v.siteInfoForm.target.required || !this.$v.siteInfoForm.target.minLength)
        return 'Please select a category'
      return null
    },
  },
  async mounted() {
    this.initializeForm()
    await this.fetchIndustries()
    this.inializeIndustryOptions()
  },
  validations() {
    const descriptionLengths = this.inputLengths.businessDescription
    return {
      siteInfoForm: {
        businessDescription: {
          required,
          minLength: minLength(descriptionLengths.min),
          maxLength: maxLength(descriptionLengths.max),
        },
        industry: {
          required,
        },
        target: {
          required,
          minLength: minLength(1),
        },
      },
    }
  },
  methods: {
    ...mapActions({
      createStore: 'store/createStore',
    }),
    initializeForm() {
      // set the default value of description
      this.siteInfoForm.businessDescription = this.context.businessDescription

      // set the default value of industry
      if (this.context?.industryName) {
        this.siteInfoForm.industry = {
          label: this.context.industryName,
          value: this.context.industryID,
        }
      }

      // set the default value of target
      this.siteInfoForm.target = this.context.target ?? []
      this[this.siteInfoForm.target[0]] = true

      this.siteInfoForm.brandTerms = this.context.brandTerms ?? []
    },
    showToast(title, message, type) {
      this.$toast.error({
        component: Toast,
        props: {
          title: title,
          message: message,
          type: type,
        },
      })
    },
    async submit() {
      this.isLoading = true
      try {
        await this.getOrganizationID()
        await this.getIndustry()

        this.$v.siteInfoForm.$touch()

        if (!this.$v.siteInfoForm.$invalid) {
          const payload = {
            organization_id: this.siteInfoForm.organization.value,
            name: this.context?.siteName,
            country_code: this.context?.siteCountry.value,
            language_code: this.context?.siteLanguage.value,
            url: this.context?.siteURL,
            type: 'standard',
            target: this.siteInfoForm.target,
            description: this.siteInfoForm.businessDescription,
            industry: this.siteInfoForm.industry.label,
            industry_id: this.siteInfoForm.industry.value,
            brandTerms: this.siteInfoForm.brandTerms,
            // competitorBrandTerms: this.siteInfoForm.competitorBrandTerms,
          }

          const responseSite = await this.$http.post('common/site', payload)
          const siteID = responseSite.data.id
          if (responseSite) {
            const payloadIntegration = {
              google_ads_customer_id: this.context.integration.id,
            }
            await this.$http.post(`common/site/${siteID}/integration/google/ads/onboarding`, payloadIntegration)
          }
          this.siteInfoForm.siteID = siteID
          this.$emit('next', this.siteInfoForm)
        }
      } catch (error) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Failed',
            message: `Sorry, we couldn't save the new site. Please try again or contact support.`,
            type: 'info',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    toggleCheckBox(type) {
      this[type] = !this[type]
      if (this[type]) {
        if (!this.siteInfoForm.target.includes(type)) this.siteInfoForm.target.push(type)
      } else {
        this.siteInfoForm.target = this.siteInfoForm.target.filter(item => item !== type)
      }
    },
    updateIndustry(industry) {
      this.siteInfoForm.industry = industry
    },
    async fetchIndustries() {
      try {
        const response = await this.$http.get('common/industry')
        if (response.status === 200) {
          this.industries = response.data
        }
      } catch (error) {
        console.error('Failed to fetch industries', error)
      }
    },
    updateBrandTerms(newList) {
      this.inputList = this.siteInfoForm.brandTerms = newList
    },
    updateCompetitorBrandTerms(newList) {
      this.siteInfoForm.competitorBrandTerms = newList
    },
    addCustomIndustry(option) {
      const newIndustry = {
        label: option.label,
        value: null,
      }
      this.industryOptions = [...this.industryOptions, newIndustry]
    },
    inializeIndustryOptions() {
      this.industryOptions = this.industries.map(industry => ({
        label: industry.name,
        value: industry.id,
      }))
    },
    async addIndustry() {
      try {
        const payload = {
          name: this.siteInfoForm.industry.label,
          organization_id: this.siteInfoForm.organization.value,
        }

        const response = await this.$http.post(`common/industry`, payload)
        this.siteInfoForm.industry = {
          label: response.data.name,
          value: response.data.id,
        }
      } catch (error) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Failed',
            message: `Sorry, we couldn't save the new industry. Please try again or select an existing option from the dropdown.`,
            type: 'info',
          },
        })
      }
    },
    async addOrganization() {
      try {
        const data = { name: this.context.organizationName }

        const response = await this.$http.post(`common/organization/onboarding`, data)
        this.siteInfoForm.organization = {
          label: response.data.name,
          value: response.data.id,
        }
      } catch (error) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Failed',
            message: `Sorry, we couldn't save the new organization. Please try again.`,
            type: 'info',
          },
        })
      }
    },
    async getOrganizationID() {
      const { organizationID, organizationName } = this.context || {}
      if (organizationID) {
        this.siteInfoForm.organization = {
          label: organizationName,
          value: organizationID,
        }
      } else {
        return await this.addOrganization()
      }
    },
    async getIndustry() {
      if (!this.siteInfoForm?.industry?.value) return await this.addIndustry()
    },
  },
}
</script>
