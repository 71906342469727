<template>
  <button
    class="org-btn bg-white border border-neutral-100 rounded-md hover:border-bb-brand-purple active:bg-bb-brand-purple text-bb-text-secondary active:text-white"
    @mousedown="setActive(true)"
    @mouseup="setActive(false)"
    @mouseleave="setActive(false)"
    @click="next"
  >
    <div
      :class="activeClass"
      class="flex flex-row py-6 px-6 justify-between"
    >
      <div
        :class="activeClass"
        class="flex flex-row items-center"
      >
        <slot
          name="left-icon"
          :is-active="isActive"
        ></slot>
        <div
          :class="activeClass"
          class="flex flex-col p1 text-left mx-6"
        >
          <slot
            name="text"
            :is-active="isActive"
          ></slot>
        </div>
      </div>
      <slot
        name="right-icon"
        :is-active="isActive"
      ></slot>
    </div>
  </button>
</template>
<script>
export default {
  name: 'OrgnizationButton',
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    activeClass() {
      return this.isActive ? 'text-white' : ''
    },
  },
  methods: {
    setActive(active) {
      this.isActive = active
    },
    next() {
      this.$emit('next')
    },
  },
}
</script>

<style scoped lang="scss">
.org-btn:focus {
  outline: $bb-brand-purple solid 2px;
  color: $bb-text-default !important;
}
</style>
